import React, { useRef } from 'react';
import { Container } from 'react-bootstrap';
import fs from 'fs';
import { render } from 'react-dom';

import { Conteudo } from './styles';

import { DropzoneArea } from 'material-ui-dropzone';
import { Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import API from '../../services/api';
import Kmlparaxlsdialog from '../../components/Kmlparaxlsdialog';
import Backdrop from '../../components/Backdrop';

// preview do kml
import { MapContainer, TileLayer } from 'react-leaflet';
import ReactLeafletKml from 'react-leaflet-kml';
import L from 'leaflet';

import Alertalogin from '../../components/Alertalogin';

// alert
import Snackbar from '@material-ui/core/Snackbar';

import ReactGA from 'react-ga';

import Usuariocrud from '../../components/Usuariocrud';

// teste
import createParser from '../../components/Getcoordenadas';

function Kmlparaxls(props) {
  const [files, setFiles] = React.useState([]);
  const [fileantigo, setFileantigo] = React.useState([]);
  const [linkfile, setLinkfile] = React.useState();
  const [linestring, setLinestring] = React.useState([]);
  const [distanciatotal, setDistanciatotal] = React.useState('');
  const [show, setShow] = React.useState(false);
  const [showfile, setShowfile] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [finalFileName, setFinalFileName] = React.useState('');
  const mapRef = useRef(null);
  // previa do kml
  const [kml, setKml] = React.useState(null);
  const [datakml, setDatakml] = React.useState({
    coordinates: [-5.412246438280968, -42.64403943128482],
    scale: 17,
  });

  const [open2, setOpen2] = React.useState({
    status: false,
    type: '',
    message: '',
  });

  const handleChange = (e) => {
    setFiles(e);
  };

  const uploadArquivo = (e) => {
    setLoading(true);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + props.user.token,
      },
    };
    const data = new FormData();
    data.append('file', files[0]);
    data.append('get_line_distance', true);

    API.post('kml-to-xls', data, config)
      .then((a) => {
        console.log(a.data);

        let auxKML = a.data.filename.split('.')[1];

        if (auxKML === 'kml') {
          API.get(`/kml-to-xls/${a.data.url.split('/')[4]}`, {
            // responseType: 'blob',
            headers: {
              Authorization: 'Bearer ' + props.user.token,
              'content-type': 'application/octet-stream',
            },
          })
            // .then((res) => res.data.text())
            .then((kmlText) => {
              let aux = createParser(kmlText.data, mapRef);
              console.log('Deu certo: ', aux);
              setDatakml({
                ...datakml,
                coordinates: [aux.center.lat, aux.center.lng],
              });

              const parser = new DOMParser();
              const kml = parser.parseFromString(kmlText.data, 'text/xml');
              setKml(kml);
            });
        } else {
          console.log('NÃO DEU CERTO');
          // setKml(null);
        }

        setLinkfile(a.data.url);
        setFinalFileName(a.data.filename);
        if (a.data.lineString.length > 0) {
          setLinestring(a.data.lineString);
          setDistanciatotal(a.data.totalDistance);
        } else {
          setLinestring([]);
          setDistanciatotal('');
        }
        setLoading(false);
        // arquivo nome
        // console.log(a.data);
        // console.log(a.data.url.slice(49,));
        // consultarDownload(a.data.url.slice(49,));
      })
      .catch((error) => {
        console.log('ERRO> ', error.response);

        if (error.response) {
          if (error.response.status == 401) {
            props.setNecessariologin(true);
          } else {
            if (typeof error.response.data.error == 'string') {
              setOpen2({
                status: true,
                type: 'error',
                message: error.response.data.error,
              });
            } else {
              setOpen2({
                status: true,
                type: 'error',
                message: 'Erro indefinido. Entre em contato com o suporte.',
              });
            }
          }
        }

        setLoading(false);
      });
  };

  // const consultarDownload=e=>{

  //   setLoading(true);
  //   const config = {
  //       headers: {
  //         "Authorization": "Bearer " + props.user.token,
  //         "responseType": "arraybuffer"
  //       }
  //     };

  //   API.get(`kml-to-xls/${e}`, config)
  //   .then(function(response) {
  //         const url = window.URL.createObjectURL(new Blob([response.data]));
  //         const link = document.createElement('a');
  //         link.href = url;
  //         const fileName = `${+ new Date()}.kml`// whatever your file name .
  //         link.setAttribute('download', fileName);
  //         document.body.appendChild(link);
  //         link.click();
  //         link.remove();// you need to remove that elelment which is created before.
  //     })
  //     .catch(function(error) {
  //           console.log(error.response)
  //   });
  // }

  React.useEffect(
    (e) => {
      if (files != fileantigo) {
        if (files.length > 0) uploadArquivo();
        setFileantigo(files);
      }
    },
    [fileantigo, files, uploadArquivo]
  );
  return (
    <Container fluid className="m-0 p-0">
      <Conteudo>
        <div className="contido">
          <div className="titulodapage">
            <h3>{'Conversor KML <> EXCEL'}</h3>
            Nesta aplicação é possível efetuar conversão mútua entre arquivos
            .KML, .KMZ, .XLS e .XLSX.
          </div>
          <br />
          <br />
          <DropzoneArea
            dropzoneClass="campodeupicon"
            dropzoneParagraphClass="campodeup"
            onChange={(e) => {
              handleChange(e);
              setShowfile(true);
            }}
            onDelete={(e) => {
              setLinkfile('');
              setLinestring([]);
              setKml('');
            }}
            acceptedFiles={['.xlsx', '.xls', '.kml', '.kmz']}
            filesLimit={1}
            maxFileSize={100000000}
            dropzoneText={`Arraste seu arquivo para este campo ou clique aqui para buscar`}
            useChipsForPreview={true}
            showPreviewsInDropzone={showfile}
            getFileAddedMessage={(fileName) =>
              `Arquivo ${fileName} adicionado com sucesso.`
            }
            getFileRemovedMessage={(fileName) =>
              `Arquivo ${fileName} removido.`
            }
            getFileLimitExceedMessage={(filesLimit) =>
              `Número máximo de ${filesLimit} arquivos excedido.`
            }
            getDropRejectMessage={() => `Tipo de arquivo não permitido.`}
          />
          <br />
          {/* <a href={linkfile? `${linkfile}?t=${props.user.token}`: '#'} style={{pointerEvents: linkfile? '': 'none'}}> */}
          <Button
            variant="contained"
            color="secondary"
            disabled={linkfile ? false : true}
            onClick={() => {
              setLoading(true);
              console.log(linkfile);
              API.get(linkfile, {
                responseType: 'blob',
                headers: {
                  Authorization: 'Bearer ' + props.user.token,
                  'content-type': 'application/octet-stream',
                },
              })
                .then((response) => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  setKml('');

                  const link = document.createElement('a');
                  link.href = url;
                  console.log('>', finalFileName);
                  link.setAttribute('download', decodeURI(finalFileName));
                  document.body.appendChild(link);
                  link.click();
                  ReactGA.event({
                    category: 'Conversor KML <> EXCEL',
                    action: 'Conversão de arquivo KML <> EXCEL e download',
                  });
                  setTimeout(function () {
                    setLinkfile('');
                    setShowfile(false);
                    setFinalFileName('');

                    setLinestring([]);
                    setLoading(false);
                  }, 10000);
                })
                .catch((error) => {
                  console.log('ERRO AQUI: ', error.response);
                  setLoading(false);
                  if (error.response) {
                    if (error.response.statusText == 'Not Found') {
                      setOpen2({
                        status: true,
                        type: 'error',
                        message: 'Arquivo não encontrado.',
                      });
                    } else {
                      if (error.response.status == 401) {
                        props.setNecessariologin(true);
                        ReactGA.event({
                          category: 'Medir cabos',
                          action:
                            'Tentativa de converter arquivo KML <> EXCEL sem realizar login',
                        });
                      } else {
                        if (typeof error.response.data.error == 'string') {
                          setOpen2({
                            status: true,
                            type: 'error',
                            message: error.response.data.error,
                          });
                        } else {
                          setOpen2({
                            status: true,
                            type: 'error',
                            message:
                              'Erro indefinido. Entre em contato com o suporte.',
                          });
                        }
                      }
                    }
                  }
                });
            }}
          >
            Download
          </Button>
          {/* </a> */}
          {linestring.length > 0 ? (
            <div style={{ maxWidth: '650px', margin: '1rem auto' }}>
              <Alert
                severity="info"
                onClick={() => setShow(true)}
                style={{ cursor: 'pointer' }}
              >
                Notamos a presença de uma ou mais linhas no seu arquivo,{' '}
                <b>clique aqui</b> para visualizar seus comprimentos.
              </Alert>
            </div>
          ) : (
            ''
          )}

          <br />
          <br />

          <div className="div__fieldPreview">
            {kml && (
              <div className="titulodapage">
                <h3>Prévia do arquivo KML</h3>
              </div>
            )}

            {kml && false && (
              <MapContainer
                style={{ height: '500px', width: '100%' }}
                zoom={datakml.scale}
                center={datakml.coordinates}
                ref={(el) => (mapRef.current = el)}
                // maxZoom={30}
                scrollWheelZoom={false}
                preferCanvas={true}
                zoomControl={true}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                {kml && <ReactLeafletKml kml={kml} />}
              </MapContainer>
            )}
          </div>
        </div>

        <br />
        <br />
        <div className="divisor" />
        {/* <Alert className="balaoNoticia" variant="filled" severity="info">Nesta aplicação já foram convertidos cerca de <b>369</b> arquivos.</Alert> */}

        {show ? (
          <Kmlparaxlsdialog
            tamanho="md"
            message={[linestring, distanciatotal]}
            negar={() => setShow(false)}
          />
        ) : (
          ''
        )}
      </Conteudo>
      <Snackbar
        open={open2.status}
        autoHideDuration={6000}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          setOpen2({ status: false, type: '', message: '' });
        }}
      >
        <Alert
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setOpen2({ status: false, type: '', message: '' });
          }}
          severity={open2.type}
        >
          {open2.message}
        </Alert>
      </Snackbar>
      {loading ? <Backdrop /> : ''}
      <Alertalogin />
    </Container>
  );
}

export default Kmlparaxls;
