import axios from 'axios';
import 'dotenv/config';

const BASE_URL = process.env.REACT_APP_API_URL;

export default axios.create({
  // baseURL: `https://apikml.alanaraujoeng.com.br/`,
  // baseURL: `https://rpapi.alanaraujoeng.com.br/app1/`
  // baseURL:`https://testekml.alanaraujoeng.com.br/`
  baseURL: `https://apikml.alanaraujo.eng.br`,
  // baseURL: `http://192.168.0.145:3332/`,
});
