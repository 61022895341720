import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { Container } from './styles';
//popover
import Popover from '@material-ui/core/Popover';

// crud do usuário 
import Usuariocrud from '../Usuariocrud';

import ReactGA from 'react-ga';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Badge from '@material-ui/core/Badge';

import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  estiloPopover:{
    ['& .MuiPopover-paper']:{
      width:window.innerWidth>675?'200px':'90%',
    }
  },
  submenuicone:{
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    display:'flex',
    flexDirection:'column', 
    width:'100%',
    margin:'0.2rem auto',
    ['& label']:{
      width:'97%',
      margin:' auto',
      padding:'0.5rem',
      color:'rgba(0,83,81,0.8)',
      fontWeight:'bold',
      fontSize:'14px',
      ['&:hover']:{
        backgroundColor:'rgba(0,0,0,0.1)',
        cursor:'pointer',
      },
      ['& a']:{
        textDecoration:'none',
        color:'rgba(0,83,81,0.8)',
      }
    }
  }
}));

function Usuarioinfo(props) {
  const classes = useStyles();
  const [showdialog,setShowdialog]= React.useState(false);

      // popover 
  const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickPopover = (event) => {
      setAnchorEl(event.currentTarget);
      window.scrollTo(0, 0);
      // setNovaFuncao({name:'', description:'', permissions:[]})
    };
    const handleClosePopover = () => {
      setAnchorEl(null);
      props.fecharmenu();
    };
    const openPopover = Boolean(anchorEl);
    const idPopover = openPopover ? 'simple-popover' : undefined;
    // fim do popover 

  return (
  <Container tamanho={props.tamanho}>
      {props.user.token?
       <div className="login-efetuado" aria-describedby={idPopover} onClick={handleClickPopover}>
               {props.user.name}
                <Badge badgeContent={4} color="secondary" invisible={true}>
                 <AccountCircleIcon style={{marginLeft:'0.2rem'}}/>
                </Badge><br/>
        </div>
      :
      <div className="efetuar-login" onClick={()=> setShowdialog(true) }>
             Login
      </div>
      }
      {showdialog || props.necessariologin? <Usuariocrud setNecessariologin={e=>props.setNecessariologin(e)} necessariologin={props.necessariologin} fechardialogo={()=>{setShowdialog(false);props.fecharmenu()}} user={props.user} userLogin={e=>props.userLogin(e)}/> :"" }
        {/* popover */}
        <Popover
        id={idPopover}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: window.innerWidth>675? 'right':'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.estiloPopover}
      >
        <div className={classes.submenuicone}>
        {/* <label><Badge badgeContent={4} color="secondary" >Novidades</Badge></label> */}
          <Link to="/configuracoes" style={{width:'100%', marginLeft:'0.2rem'}}><label>Configurações</label></Link>
          <label onClick={()=> {
            props.userLogoff();
            handleClosePopover();
            ReactGA.event({
              category: 'Usuário',
              action: 'Logout'
            });
            }}>Sair</label>
        </div>
      </Popover>
  </Container>
    );
}

export default Usuarioinfo;