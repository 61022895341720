import React from 'react';
import logo from './logo.svg';
import './App.css';
import '../node_modules/react-vis/dist/style.css';
import ScrollToTop from './ScrollToTop.js';

import {parseISO, addDays, addMinutes} from 'date-fns';

// React router dom 
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

import { createBrowserHistory } from 'history';

//gerais
import Gerais from './components/Gerais';

// fixos
import Menu from './components/Menu';
import Footer from './components/Footer';
import Floatingactionbutton from './components/Floatingactionbutton';

// páginas
import Home from './pages/Home';
import Kmlparaxls from './pages/Kmlparaxls';
import Medircabos from './pages/Medircabos';
import Ordenarpontos from './pages/Ordenarpontos';
import Nomedasruas from './pages/Nomedasruas';
import Configuracoes from './pages/Configuracoes';
import Confirmacao from './pages/Confirmacao';
import Directlogin from './pages/Directlogin';
import Resetarsenha from './pages/Resetarsenha';
import Termos from './pages/Termos';
import Politica from './pages/Politica';

//Tema principal
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

//cookies
import Cookies from 'universal-cookie';

// google Analytics
import ReactGA from 'react-ga';

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main:'rgba(0,83,81,1)',
    },
  },
});


function App() {

  const cookies = new Cookies();
  const [user, setUser] = React.useState({
    name:'',
    email:'',
    token:'',
    id:''
  })
  const [necessariologin, setNecessariologin] = React.useState(false);

  const history = createBrowserHistory();
  
  const userLogin=e=>{
    cookies.set('u3u2u4u25dq1996-11-07-AcLu-e96-07e', e, { path: '/', expires: addDays(new Date(),14)  });
    setUser({
      name:e.name,
      email:e.email,
      token:e.token,
      id:e.id
    });
  }
  const userLogoff=e=>{
    setUser({
      name:'',
      email:'',
      token:'',
      id:''
    });
    cookies.set('u3u2u4u25dq1996-11-07-AcLu-e96-07e', "", { path: '/' });
  }

  // Initialize google analytics page view tracking
  const trackingId = "UA-178157663-1"; // Replace with your Google Analytics tracking ID
  ReactGA.initialize(trackingId);
  history.listen(location => {
    ReactGA.set({ page: location.pathname, userId: user.id, }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });

  React.useEffect(a=>{
      if(cookies.get('u3u2u4u25dq1996-11-07-AcLu-e96-07e')){
        const e = cookies.get('u3u2u4u25dq1996-11-07-AcLu-e96-07e');
        // setUser({name:e.name, token: e.token, email:e.email, id:e.id});
        if(e.name && !user.name) setUser({name:e.name, token: e.token, email:e.email, id:e.id});
      }
      // if(backdrop) callBackdrop();
  },[])

  return (
    <div className="App" style={{backgroundColor: "rgba(0,0,0,0.08)"}}>
       <ThemeProvider theme={defaultMaterialTheme}>
       <Router history={history}>
       <ScrollToTop>
       <Menu user={user} userLogin={e=>userLogin(e)} userLogoff={e=>userLogoff()} setNecessariologin={e=>setNecessariologin(e)} necessariologin={necessariologin}/>
       <div style={{marginTop:'6rem', marginBottom:'5rem'}}>
       <Switch>
         <Route exact path="/"><Redirect to={{pathname:"/home"}}/></Route>
         <Route path="/home" children={<Home user={user}/>} />
         <Route path="/kml-para-excel" children={<Kmlparaxls user={user} setNecessariologin={e=>setNecessariologin(e)}/>}/>
         <Route path="/medir-cabos" children={<Medircabos user={user} setNecessariologin={e=>setNecessariologin(e)}/>} />
         <Route path="/ordenar-pontos" children={<Ordenarpontos user={user} setNecessariologin={e=>setNecessariologin(e)}/>} />
         <Route path="/nome-das-ruas" children={<Nomedasruas user={user} setNecessariologin={e=>setNecessariologin(e)}/>} />
         <Route path="/configuracoes" children={(user.token && user.name && user.email)? <Configuracoes user={user} userLogoff={e=>userLogoff()} setNecessariologin={e=>setNecessariologin(e)}/>:<Redirect to={{pathname:"/home"}}/>} />
         
         <Route path="/direct-login/" children={(user.token && user.name && user.email)?<Redirect to={{pathname:"/home"}}/>: <Directlogin user={user} userLogin={e=>userLogin(e)}/>} /> 
         <Route path="/confirmacao/:token" children={<Confirmacao user={user}/>} />
         <Route path="/redefinir-senha/:token" children={<Resetarsenha user={user}/>} />
         <Route path="/termos" children={<Termos/>} />
         <Route path="/politica" children={<Politica/>} />
         
       </Switch>
       </div>
       <Floatingactionbutton/>
       <Footer/>
       
       <Gerais/>
       </ScrollToTop>
      </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
