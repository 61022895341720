import styled from 'styled-components';

export const Conteudo = styled.div`

    .iconesList{
       
    }
    .iconeLink{
        margin: 0.70rem;
        padding: 0.1rem;
    }
    .iconeLink:hover{
        padding: 0.1rem;
        border-radius: 50%;
        opacity: 60%;
        transition: 0.3s;
    }

    span{
        font-family: Helvetica,Campton-Light,Sans-serif;
        font-size: 13px;
        color: #fff;
    }
`;
