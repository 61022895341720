import React from 'react';
import { Container } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import {Card} from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';
// icones
import {TiDocumentText, TiPlusOutline} from 'react-icons/ti';
import {AiOutlineFundProjectionScreen, AiFillSafetyCertificate} from 'react-icons/ai';
import {FaRegLightbulb, FaPlus} from 'react-icons/fa';
import {IoIosPaper} from 'react-icons/io';
// icones personalizados

const useStyles = makeStyles({
    root: {
      height: 175,
      minWidth: window.innerWidth> 675? 'auto':275,
      flex: 1,
      margin: 'auto',
      marginBottom:'0',
      boxShadow: '0px 0px 0px #a0a0a0',
      cursor: 'pointer',
      fontSize: '16px',
      opacity:'90%',
      '& svg':{
          margin: '1rem',
      },
      '&:hover':{
        display: 'inline-block',
        opacity:'100%',
        boxShadow: '0px 3px 3px #a0a0a0',
        borderTop: '5px solid rgba(0,83,81,1)',
        // border: '1px solid rgba(0,83,81,1)',
      },
    },
    root2:{
        height: 175,
        minWidth: window.innerWidth> 675? 'auto':275,
        flex: 1,
        margin: 'auto',
        marginBottom:'0',
        boxShadow: '0px 0px 0px #a0a0a0',
        fontSize: '16px',
        '& svg':{
            margin: '1rem',
            transition: '0.5s',
        },
        '&:hover':{
            '& svg':{
                margin: '1rem',
                transform: 'rotate(90deg)',
                transition: '0.5s',
            }
        },
        
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
      fontSize:'13px',
    },
   
    telaMaxima:{
        maxWidth: '1024px',
        margin:'auto',
        display: 'flex',
        flexDirection: window.innerWidth>675? 'row':'column',
        alignItems: 'center',
        alignContent: 'center',
        textAlign: 'center',
    }
  });

function Nossosservicos() {
    const [focar, setFocar] = React.useState(false);
    const classes = useStyles();

    return(
        <Container fluid className="m-0" variant="dark" style={{backgroundColor:'#fff',padding: ' 4rem 0rem',}} id="servicos" >
            <a href="#servicos"><hr style={{height:'8px', width:'6rem', backgroundColor:'#000', borderRadius:'20px', opacity:'20%', margin:'0rem auto'}}/></a>
            <Typography  variant="h4" component="h4" style={{margin:'2rem 1rem', letterSpacing:'0.2rem', color:'rgba(0,83,81,1)'}}>
                NOSSOS SERVIÇOS
            </Typography>
            <div className={classes.telaMaxima }>
             <Card className={classes.root} variant="outlined" style={{ margin:'0.5rem'}} >
                <CardContent>
                    <Typography className={classes.pos} color="textSecondary">
                        <IoIosPaper size={70} color="rgba(0,83,81,1)"/> <br/>
                    Outorga e<br/>Licenciamentos
                    </Typography>
                </CardContent>
                {/* <CardActions>
                    <Button size="small">Learn More</Button>
                </CardActions> */}
            </Card>
            <Card className={classes.root} variant="outlined" style={{ margin:'0.5rem'}} >
                <CardContent>
                    <Typography className={classes.pos} color="textSecondary">
                        <img src='../files/images/Iconspersonalisados/papel-caneta.png' style={{width:'65px', margin: '0.8rem',}}/> <br/>
                        Consultoria<br/>Pós-Outorga (SICI/FUST)
                    </Typography>
                </CardContent>
            </Card>
            <Card className={classes.root} variant="outlined" style={{ margin:'0.5rem'}} >
                <CardContent>
                    <Typography className={classes.pos} color="textSecondary">
                        <img src='../files/images/Iconspersonalisados/fiber-icon.png' style={{width:'65px', margin: '0.8rem',}}/> <br/>
                        Projetos de<br/> Fibra Óptica (FTTh)
                    </Typography>
                </CardContent>
            </Card>
            <Card className={classes.root} variant="outlined" style={{ margin:'0.5rem'}} >
                <CardContent>
                    <Typography className={classes.pos} color="textSecondary">
                        <img src='../files/images/Iconspersonalisados/telephone-pole.png' style={{width:'65px', margin: '0.8rem',}}/> <br/>
                        Compartilhamento<br/>de Postes
                    </Typography>
                </CardContent>
            </Card>
            <Card className={classes.root} variant="outlined" style={{ margin:'0.5rem'}}>
                    <CardContent>
                        <Typography className={classes.pos} color="textSecondary" style={{marginTop:'-1rem'}}>
                        <AiOutlineFundProjectionScreen size={70} color="rgba(0,83,81,1)"/> <br/>
                        Cursos de Fibra Óptica (Online e Presencial)
                        </Typography>
                    </CardContent>
                </Card>
                <a href="http://alanaraujo.eng.br/servicos"  style={{ textDecoration: 'none' }}>
                <Card className={classes.root2} variant="outlined" style={{ margin:'0.5rem',border:0,opacity:'85%'}}>
                    <CardContent>
                        <Typography className={classes.pos} style={{fontSize:'16px',fontFamily:" 'Gill Sans', 'Gill Sans MT', arial-black, 'Trebuchet MS', sans-serif' ", color:"rgba(0,83,81,1)"}}>
                            <FaPlus size={45} color={"rgba(0,83,81,1)"}/> <br/>
                            E muito mais!<br/>
                            Acompanhe e confira
                        </Typography>
                    </CardContent>
                </Card>   
                </a>
            </div>
        </Container>
    );
}

export default Nossosservicos;