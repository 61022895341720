import styled from 'styled-components';

export const Conteudo = styled.div`
    margin:auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;

    .contido{
        width: 100%;
        max-width: 1024px;
        border-radius: 5px;
        background-color: rgba(255,255,255,1);
        box-shadow: 0px 1px 5px rgba(0,0,0,0.2);

   
        padding: 1rem;
        padding-right: 2rem;
        

        .titulodapage{
            text-align: left;
            font-family: Arial, Helvetica, sans-serif;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            padding: 0.5rem;
            color: rgba(0,0,0,0.7);
        }
        a{
            text-decoration: none;
        }

        /* subreparticao */
        .subReparticao{
            text-align: center;
            font-family: Arial, Helvetica, sans-serif;
            margin-left: ${window.innerWidth>675? '0rem': '-1rem'};
            padding: 1rem 0.5rem;
            color: rgba(0,0,0,0.7);

            .subTitulo{
                margin-left: ${window.innerWidth>675? '0rem': '1rem'};
                font-size: 14px;
                border-bottom: 1px solid rgba(0,0,0,0.1);
                padding: 0.5rem;
                color: rgba(0,0,0,0.7);
            }
        }
    }

    .campodeup{
        font-size: 25px !important;
        color: rgba(0,83,81,1);
    }
    .campodeupicon{
        background-color: #ffffff;
        border-color: rgba(0,83,81,1);
        padding: 1rem;
        width: ${window.innerWidth>675? '500px': '100%'};
        height: auto;
        margin:auto;
        svg{
            color: rgba(0,83,81,1);
        }
    }

    .cards{
        display:flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items:center;
        align-content:center;
        justify-content:center;
        margin:1rem auto;
        
    }

    .infoDeck{
        display:flex;
        flex-direction: column;
        align-items:center;
        align-content:center;
        justify-content:center;
        justify-items:center;
        margin: 0rem 2rem;

        .infoCard{
            margin:auto;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            margin-bottom: 1rem;
            background-color: rgba(18, 147, 154, 0.05);
            /* box-shadow: 0px 0px 4px rgba(0,0,0,0.3); */
            padding: 1rem;
            border-radius: 5px;
            width: 100%;

            h1{
                font-size: 50px;
                color: #12939A;
            }
            label{
                opacity: 90%;
                font-size: 14px;
                font-weight: bold;
                color: #12939A;
                /* border-bottom: 2px dashed #12939A; */
            }

        }
    }
`;

export const Cartao=styled.div`
    display: flex;
    flex-direction: column;
    background: ${(props)=> props.bg};
    color: ${(props)=> props.color};
    width: ${window.innerWidth>675? '220px':'100%'};
    min-height:${window.innerWidth>675? '180px':''}; ;
    padding:1rem;
    margin:0.1rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
    img,svg{
        width:${window.innerWidth>675? "50%":"30% !important"};
        margin:auto;
    }
    h6{
        margin-bottom: 0.5rem;
    }
    p{
        font-size: 12px;
        text-align: justify;
       
    }
    
    :hover{
        box-shadow: 0px 0px 4px rgba(0,0,0,0.8);
        transform: scale(1.05);
    }
`;