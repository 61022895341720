import styled from 'styled-components';

export const Container = styled.div`
margin-left: 0.5rem;
margin-bottom:0rem;
margin-top: 0.85rem;

.login-efetuado{

    display: inline;
    background-color: #008080 !important;
    /* color: #3a3b3a; */
    color: #ffffff;
    padding: ${(props)=> props.tamanho=='img01'?'2.48rem':'1.90rem 2.48rem' };
    transition: 1s;
    margin-right:-1rem;
    cursor: pointer;
    opacity: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;

    :hover{
        opacity: 80%;
    }

@media(max-width: 970px){
    
    font-size:14px;
        margin-left: ${window.innerWidth>675? '10px': '0px'};
        align-items: center;
        align-content: center;
        color:  rgba(255,255,255,1)!important;
        background-color: rgba(0,84,83,1) !important;
        border-radius: 4px;
        padding: 0rem 1rem;

        a:hover, a:active{
            border-bottom: 0px !important;
            opacity: 100%;
            transition: 0.5s;
        }

    display:flex;
    align-items:center;
    align-content:center;
    justify-content:center;
    margin-bottom: 0.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgba(0,0,0,0.5);
    padding:0.5rem;
    margin: 0.2rem auto;
    margin-right:0.5rem;
    cursor: pointer;
    /* border-bottom: 2px solid rgba(255,255,255,2); */
    font-weight: bold;
    opacity: 80%;

    :hover{
        opacity: 100%;
    }
}
}

.efetuar-login{
    display: inline;
    background-color: #008080 !important;
    color: rgba(255,255,255,1);
    padding: ${(props)=> props.tamanho=='img01'?'2.48rem':'1.90rem 2.48rem' };
    transition: 1s;
    margin-right:-1rem;
    cursor: pointer;
    opacity: 90%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;

    :hover{
        opacity: 80%;
    }

  @media(max-width: 940px){
            
    margin-left: ${window.innerWidth>675? '10px': '0px'};
        align-items: center;
        align-content: center;
        color:  rgba(255,255,255,1)!important;
        background-color: rgba(0,84,83,1) !important;
        border-radius: 4px;
        padding: 0rem 1rem;

        a:hover, a:active{
            border-bottom: 0px !important;
            opacity: 100%;
            transition: 0.5s;
        }

    display:flex;
    align-items:center;
    align-content:center;
    justify-content:center;
    margin-bottom: 0.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgba(0,0,0,0.5);
    padding:0.5rem;
    margin: 0.2rem auto;
    margin-right:0.5rem;
    cursor: pointer;
    border-bottom: 2px solid rgba(255,255,255,2);
    font-weight: bold;
    opacity: 80%;

    :hover{
        border-bottom: 2px solid rgba(0,0,0,0.5);
        padding: 0.5rem;
    }
  }
}
  
`;