import React from 'react';
import { Container } from 'react-bootstrap';

import {Conteudo} from './styles';

import {DropzoneArea} from 'material-ui-dropzone';
import {Button} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import API from '../../services/api';
import Kmlparaxlsdialog from '../../components/Kmlparaxlsdialog';
import ReactGA from 'react-ga';

import Backdrop from '../../components/Backdrop';
// alert 
import Snackbar from '@material-ui/core/Snackbar';

function Medircabos(props) {
    const [files, setFiles] = React.useState([]);
    const [fileantigo, setFileantigo] = React.useState([]);
    const [linkfile, setLinkfile] = React.useState();
    const [linestring, setLinestring]= React.useState([]);
    const [distanciatotal, setDistanciatotal] = React.useState('');
    const [show, setShow] = React.useState(false);
    const [showfile,setShowfile]= React.useState(false);
    const [notcables,setNotcables] = React.useState(false);
    const [loading,setLoading] = React.useState(false);
    const [finalFileName,setFinalFileName] = React.useState('');

    const [open2,setOpen2]=React.useState({
      status: false,
      type:'',
      message:'',
    })

    const handleChange= e=>{
        setFiles(e);
    }

    const uploadArquivo=e=>{
      setLoading(true);
      // console.log("token: ",props.user.token);
        const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer " + props.user.token,
            }
          };
          const data = new FormData();
          data.append('file', files[0]);

        API.post('measure_cables', data, config)
        .then(a=>{
            console.log(a.data);
            setLinkfile(a.data.url);
            setFinalFileName(a.data.filename);
            if(a.data.lineString.length>0){
                setLinestring(a.data.lineString);
                setDistanciatotal(a.data.totalDistance);
                setShow(true);
                setNotcables(false);
                console.log(a.data);
                ReactGA.event({
                  category: 'Medir cabos',
                  action: 'visualização de medida dos cabos'
                });
    
            }else{
                setLinestring([]);
                setDistanciatotal('');
                setNotcables(true);
            }
            setLoading(false);
        }).catch(error=>{
            console.log(error.response);
           if(error.response){ 
             if(error.response.status==401){
              props.setNecessariologin(true);
              ReactGA.event({
                category: 'Medir cabos',
                action: 'Tentativa de visualizar cabos sem efetuar login'
              });
            }else{
              if(typeof(error.response.data.error)=='string' ){
                setOpen2({status:true,type:'error',message:error.response.data.error});
              }else{
                setOpen2({status:true,type:'error',message:"Erro indefinido. Entre em contato com o suporte."});
              }
            }}
           

            setLoading(false);
        
        })
    }

    React.useEffect(e=>{
        if(files != fileantigo){
          if(files.length>0) uploadArquivo() ;
          setFileantigo(files);
        }
    },[uploadArquivo]);
  return(
    <Container fluid className="m-0 p-0">
        <Conteudo>
          <div className="contido">
              <div className="titulodapage">
                    <h3>{"Medir Cabos"}</h3>
                    Nesta aplicação é possível obter o comprimento dos cabos utilizando arquivos .KML e .KMZ.
              </div>

            <br/><br/>
            <DropzoneArea
                dropzoneClass="campodeupicon"
                dropzoneParagraphClass="campodeup"
                onChange={(e) => {handleChange(e); setShowfile(true);}}
                onDelete={(e)=> {setLinkfile("")}}
                acceptedFiles={['.kml','.kmz']}
                filesLimit={1}
                maxFileSize={100000000}
                dropzoneText={`Arraste seu arquivo para este campo ou clique aqui para buscar`}
                useChipsForPreview={true}
                showPreviewsInDropzone={showfile}
                getFileAddedMessage={(fileName)=>`Arquivo ${fileName} adicionado com sucesso.`}
                getFileRemovedMessage={(fileName)=>`Arquivo ${fileName} removido.`}
                getFileLimitExceedMessage={(filesLimit)=>`Número máximo de ${filesLimit} arquivos excedido.`}
                getDropRejectMessage={()=>`Tipo de arquivo não permitido.`}
                
           />
            <br/>
            {/* <a href={linkfile? linkfile: '#'} style={{pointerEvents: linkfile? '': 'none'}}>
            <Button 
                variant="contained" 
                color="secondary" 
                disabled={linkfile? false: true}
            >
                Download
            </Button>
            </a> */}
            {notcables?
            <div style={{maxWidth:'650px', margin:'1rem auto'}}>
                 <Alert severity="warning" onClick={()=> setShow(true)} style={{cursor:'pointer'}}>Não foi encontrado cabo(s) no arquivo importado.</Alert>
            </div>:''}

            
          </div>
          <br/>
          <br/>
          <div className="divisor"/>
          {/* <Alert className="balaoNoticia" variant="filled" severity="info">Nesta aplicação já foram medidos cerca de <b>369</b> metros de cabo.</Alert> */}
          

              {show?
                <Kmlparaxlsdialog
                user={props.user}
                tamanho="md"
                message={[linestring,distanciatotal]}
                linkfile={linkfile}
                finalFileName={finalFileName}
                setNecessariologin = {e=>props.setNecessariologin(e)}
                // apagar={e=>{
                //   setTimeout  (function(){
                //     setLinkfile("");
                //     setShowfile(false);
                //     // setFinalFileName('')
                //     setLinestring([]);
                //     setLoading(false);
                //   }, 10000);
                // }}
                negar={()=>{
                  setShow(false);
                  setTimeout  (function(){
                    setShowfile(false);
                  }, 1000);
                }}
              />:''}

        </Conteudo>
        <Snackbar open={open2.status} autoHideDuration={6000} onClose={(event,reason)=>{  if (reason === 'clickaway') {return;}; setOpen2({status:false, type:'', message:''});}}>
            <Alert onClose={(event,reason)=>{  if (reason === 'clickaway') {return;}; setOpen2({status:false, type:'', message:''});}} severity={open2.type}>
              {open2.message}
            </Alert>
          </Snackbar>
        {loading? <Backdrop/> :''}
    </Container>
  );
}

export default Medircabos;