import React from 'react';
import { Container } from 'react-bootstrap';

import {Conteudo, Cartao} from './styles';
import {Link} from 'react-router-dom';

import {DropzoneArea} from 'material-ui-dropzone';
import {Button} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import API from '../../services/api';
import Kmlparaxlsdialog from '../../components/Kmlparaxlsdialog';
import Backdrop from '../../components/Backdrop';
import Formmensagem from '../../components/Formmensagem';

import SvgIcon from '@material-ui/core/SvgIcon';
import Logo1 from '../../files/images/icons/kmlparaexcel2.svg';
import Logo2 from '../../files/images/icons/medircabos.svg';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import image1 from '../../files/images/icons/kmlparaexcel.png';
import image2 from '../../files/images/icons/medircabos2.png';
import image3 from '../../files/images/icons/orderpoints.png';

import Logocontact from '@material-ui/icons/Announcement';
import Alertalogin from '../../components/Alertalogin';

import AccountTreeIcon from "@material-ui/icons/AccountTree";

// graficos 
import {
  XYPlot, 
  LineSeries, 
  VerticalGridLines, 
  HorizontalGridLines, 
  XAxis,
  YAxis,
  VerticalBarSeries,
  VerticalBarSeriesCanvas,
  DiscreteColorLegend
} from 'react-vis';
import {curveCatmullRom} from 'd3-shape';

function Home(props) {
    const [files, setFiles] = React.useState([]);
    const [fileantigo, setFileantigo] = React.useState([]);
    const [linkfile, setLinkfile] = React.useState();
    const [linestring, setLinestring]= React.useState([]);
    const [distanciatotal, setDistanciatotal] = React.useState('');
    const [show, setShow] = React.useState(false);
    const [showfile,setShowfile]= React.useState(false);
    const [loading,setLoading] = React.useState(false);
    
    const [dialogo, setDialogo] = React.useState(false);

    const BarSeries =  VerticalBarSeries;

    
    // metricas 
    const [dadosAcessos, setDadosacessos]=React.useState([
      {x: "01/08", y: 8},
      {x: '02/08', y: 5},
      {x: '03/08', y: 4},
      {x: '04/08', y: 9},
      {x: '05/08', y: 1},
      {x: '06/08', y: 7},
      {x: '07/08', y: 6},
      {x:'08/08', y: 3},
      {x:'09/08', y: 2},
    ]);
    const [dadosArquivos, setDadosarquivos]=React.useState([
      {x: '02/08', y: 5},
      {x: '03/08', y: 4},
      {x:'08/08', y: 3},
      {x: '06/08', y: 7},
      {x: '07/08', y: 6},
      {x:'09/08', y: 2},
      {x: "01/08", y: 8},
      {x: '04/08', y: 9},
      {x: '05/08', y: 1},
      
    ])

    const handleChange= e=>{
        setFiles(e);
    }

    const uploadArquivo=e=>{
      setLoading(true);
        const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer " + props.user.token,
            }
          };
          const data = new FormData();
          data.append('file', files[0]);

        API.post('kml-to-xls', data, config)
        .then(a=>{
            setLinkfile(a.data.url);
            if(a.data.lineString.length>0){setLinestring(a.data.lineString);setDistanciatotal(a.data.totalDistance);}else{setLinestring([]);setDistanciatotal('');}
            setLoading(false);
        }).catch(error=>{
            console.log(error.response);
            setLoading(false);
        })
    }

    React.useEffect(e=>{
        if(files != fileantigo){
          if(files.length>0) uploadArquivo() ;
          setFileantigo(files);
        }
    },[uploadArquivo]);


  return(
    <Container fluid className="m-0 p-0" >
        <Conteudo>
          <div className="contido">
              <div className="titulodapage">
                    <h3>{"Acesse todas as nossas ferramentas"}</h3>
                    {/* Nesta aplicação é possível efetuar conversão mútua entre arquivos .KML, .KMZ, .XLS e .XLSX. */}
              </div>
              <div className="cards">
                <Link to="/kml-para-excel">
                  <Cartao bg={"#f6511d"} color={"#ffffff"} >
                  {/* <object style={{width:'50%',}} type="image/svg+xml" data={Logo1}></object> */}
                  <img src={image1} style={{width:'50%',}}/>
                    <div>
                      <h6>{'Conversor KML<>Excel'}</h6>
                      <p>Converta de forma mútua seus arquivos KML e KMZ para excel.</p>
                    </div>
                  </Cartao>
                </Link>
                <Link to="/medir-cabos">
                  <Cartao bg={"#ffb400"} color={"#ffffff"}>
                    {/* <object style={{width:'60%',}} type="image/svg+xml" data={Logo2}></object> */}
                    <img src={image2} style={{width:'50%',}}/>
                    <div>
                      <h6>{'Medir Cabos'}</h6>
                      <p>Obtenha o comprimento dos cabos utilizando arquivos .KML e .KMZ.</p>
                    </div>
                  </Cartao>
                </Link>
                <Link to="/ordenar-pontos">
                  <Cartao bg={"#00a6ed"} color={"#ffffff"}>
                    {/* <object style={{width:'60%',}} type="image/svg+xml" data={Logo2}></object> */}
                    <img src={image3} style={{width:'50%',}}/>
                    <div>
                      <h6>{'Ordenar Pontos'}</h6>
                      <p>Ordene os pontos/marcadores por proximidade.</p>
                    </div>
                  </Cartao>
                </Link>
                <Link to="/nome-das-ruas">
                  <Cartao bg={"#20A362"} color={"#ffffff"}>
                    {/* <object style={{width:'60%',}} type="image/svg+xml" data={Logo2}></object> */}
                    <LocationOnIcon style={{fontSize:'40px'}}/>
                    {/* <img src={image3} style={{width:'50%',}}/> */}
                    <div>
                      <h6>{'Nome das Ruas'}</h6>
                      <p>Obtenha o nome da rua em que se encontra os pontos/marcadores.</p>
                    </div>
                  </Cartao>
                </Link>
                <a href="http://fluxograma-fttx-iota.vercel.app/" target='_blank'>
                  <Cartao bg={"#e1306c"} color={"#ffffff"}>
                    {/* <object style={{width:'60%',}} type="image/svg+xml" data={Logo2}></object> */}
                    <AccountTreeIcon style={{fontSize:'40px'}}/>
                    {/* <img src={image3} style={{width:'50%',}}/> */}
                    <div>
                      <h6>{'Fluxograma FTTX'}</h6>
                      <p>Desenha fluxogramas calculando potência de sinal.</p>
                    </div>
                  </Cartao>
                </a>
                <Cartao bg={"#7fb800"} color={"#ffffff"} onClick={()=>setDialogo(true)} style={{cursor:'pointer'}}>
                  <Logocontact fontSize={'large'} style={{marginBottom:'0.2rem'}}/>
                  <div>
                    <h6>{'Deixe sua Sugestão'}</h6>
                    <p>Possui alguma sugestão de ferramenta ou conteúdo para a plataforma? Entre em contato.</p>
                  </div>
                </Cartao>
              </div>
            <br/>
          </div>

              {show?
                <Kmlparaxlsdialog
                tamanho="md"
                message={[linestring,distanciatotal]}
                negar={()=>setShow(false)}
              />:''}

        </Conteudo>
                  {/* INFORMATIVO  */}
        {/* <Conteudo>
          <div className="contido">
               <div className="titulodapage">
                    <h3>{"Nossas métricas"}</h3>
              </div>
              
              <div style={{padding:'2rem 1rem', display:'flex', flexDirection: window.innerWidth>675? 'row':'column', alignItems:'center', justifyContent:'center'}}>
                    
                    <XYPlot
                      className="clustered-stacked-bar-chart-example"
                      xType="ordinal"
                      stackBy="y"
                      width={250}
                      height={250}
                      style={{margin:'0rem 2rem !important'}}
                    >
                      <DiscreteColorLegend
                        style={{position: 'absolute', left: '50px', top: '00px',backgroundColor:'rgba(255,255,255,0.7)'}}
                        orientation="vertical"
                        items={[
                          {
                            title: 'Acessos',
                            color: '#12939A'
                          },
                        ]}
                      />
                      <VerticalGridLines />
                      <HorizontalGridLines />
                      <XAxis />
                      <YAxis />
                      <BarSeries
                        cluster="2020"
                        color="#12939A"
                        data={dadosAcessos.slice(0,6)}
                      />
                    
                    </XYPlot>
                    <XYPlot
                      className="clustered-stacked-bar-chart-example"
                      xType="ordinal"
                      stackBy="y"
                      width={250}
                      height={250}
                      style={{margin:window.innerWidth>675?'0rem 2rem ':'0rem'}}
                    >
                      <DiscreteColorLegend
                        style={{position: 'absolute', left: '50px', top: '00px',backgroundColor:'rgba(255,255,255,0.7)'}}
                        orientation="vertical"
                        items={[
                          {
                            title: 'Arq. Convertidos',
                            color: '#e53d00',
                            
                          },
                        ]}
                      />
                      <VerticalGridLines />
                      <HorizontalGridLines />
                      <XAxis />
                      <YAxis />
                      <BarSeries
                        cluster="2020"
                        color="#e53d00"
                        data={
                          dadosArquivos.slice(0,6)
                          }
                      />
                    
                    </XYPlot>
                    <div className="infoDeck">
                          <div className="infoCard">
                            
                            <label>USUÁRIOS CADASTRADOS</label>
                            <h1>192</h1>
                          </div>
                          <div className="infoCard">
                             
                            <label>LOGINS ATIVOS</label>
                            <h1>9</h1>
                          </div>
                    </div>
                    </div>
          </div>
        </Conteudo> */}
        {loading? <Backdrop/> :''}
        <Alertalogin/>
        {dialogo? <Formmensagem dialogo={dialogo} closeDialogo={()=>setDialogo(false)}/> :''}
    </Container>
  );
}

export default Home;