import React, { useState } from "react";
import { Fab, Zoom, Tooltip, makeStyles } from "@material-ui/core";
import {
	GoPlus,
	MdClose,
	FaWhatsapp,
	FaInstagram,
	FaYoutube,
	FaFacebookF,
} from "react-icons/all";
import Imageicon1 from "../../files/images/icons/menusiteprincipal.png";

const useStyles = makeStyles((theme) => ({
	fab: {
		position: "fixed",
		bottom: theme.spacing(4),
		right: theme.spacing(4),
		zIndex: 1000,
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
	actionButton: {
		marginBottom: theme.spacing(2),
		display: "flex",
		justifyContent: "flex-end",
	},
}));

export default function FloatingActionButtonCustom() {
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	const actions = [
		{
			icon: <FaFacebookF size={35} color="white" />,
			tooltip: "Facebook",
			href: "https://www.facebook.com/AlanRodriguesA",
			color: "#3b5998",
		},
		{
			icon: <FaYoutube size={35} color="white" />,
			tooltip: "Youtube",
			href: "https://www.youtube.com/alanaraujoeng",
			color: "#bb0000",
		},
		{
			icon: <FaInstagram size={35} color="white" />,
			tooltip: "Instagram",
			href: "https://instagram.com/alanaraujoeng",
			color: "#E1306C",
		},
		{
			icon: <FaWhatsapp size={35} color="white" />,
			tooltip: "Whatsapp",
			href: "https://wa.me/5588993528826?text=Tenho%20interesse%20em%20solicitar%20or%C3%A7amento%20dos%20seus%20servi%C3%A7os",
			color: "#34af23",
		},
		{
			icon: (
				<img
					src={Imageicon1}
					style={{ width: "35px", height: "35px" }}
					alt="Site"
				/>
			),
			tooltip: "Site Alan Araújo Eng.",
			href: "http://www.alanaraujo.eng.br",
			color: "#fff",
		},
	];

	return (
		<div className={classes.fab}>
			{actions.map((action, index) => (
				<Zoom
					key={action.tooltip}
					in={open}
					style={{ transitionDelay: open ? `${index * 100}ms` : "0ms" }}
				>
					<div className={classes.actionButton}>
						<Tooltip title={action.tooltip} placement="left">
							<a
								href={action.href}
								target="_blank"
								rel="noopener noreferrer"
								style={{
									backgroundColor: action.color,
									padding: "0.7rem",
									borderRadius: "50%",
									display: "inline-block",
								}}
							>
								{action.icon}
							</a>
						</Tooltip>
					</div>
				</Zoom>
			))}

			<Fab
				color="primary"
				onClick={handleClick}
				style={{ backgroundColor: "#f45b69" }}
			>
				{open ? (
					<MdClose size={35} color="white" />
				) : (
					<GoPlus size={35} color="white" />
				)}
			</Fab>
		</div>
	);
}
