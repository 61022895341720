import React, { ReactNode, useRef } from 'react';

import { Container } from './styles';
import * as L from 'leaflet';
import 'leaflet-kml';

function Getcoordenadas(arquivo, mapRef) {
  const kml = new DOMParser().parseFromString(arquivo, 'text/xml');
  // @ts-ignore

  console.log('Chegou aqui: ', kml);

  const geoJosnOverlay = new L.KML(kml);
  const geoJsonBounds = geoJosnOverlay.getBounds();
  // const zoom = mapRef.current.leafletElement.getBoundsZoom(geoJsonBounds);
  const center = geoJsonBounds.getCenter();

  return { zoom: true, center: center };
  // return true;
}

export default Getcoordenadas;
