import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import fs from 'fs';
import { Conteudo } from './styles';
import axios from 'axios';
import { DropzoneArea } from 'material-ui-dropzone';
import { Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import API from '../../services/api';
import Kmlparaxlsdialog from '../../components/Kmlparaxlsdialog';
import Backdrop from '../../components/Backdrop';
import archivemodel from '../../files/examples/arquivo_modelo_-_nome_de_ruas_dos_marcadores_-_Ferramentas_Alan_Araujo_Telecomunicacoes.xlsx';
import Alertalogin from '../../components/Alertalogin';
import * as XLSX from 'xlsx';
import xlsx from 'json-as-xlsx';
// alert
import Snackbar from '@material-ui/core/Snackbar';

import getRoads from '../../services/getRoads'

function Nomedasruas(props) {
  const [files, setFiles] = React.useState([]);
  const [fileantigo, setFileantigo] = React.useState([]);
  const [linkfile, setLinkfile] = React.useState();
  const [linestring, setLinestring] = React.useState([]);
  const [distanciatotal, setDistanciatotal] = React.useState('');
  const [show, setShow] = React.useState(false);
  const [showfile, setShowfile] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [ruas, setRuas] = React.useState([]);
  const [dados, setDados] = React.useState('');
  const [tamTotal, setTamTotal] = React.useState();
  const [ajustar, setAjustar] = React.useState(false);
  const [dados2, setDados2] = React.useState([]);
  const [baixar, setBaixar] = React.useState(false);
  const [nomeDoArquivo, setNomeDoArquivo] = React.useState('');
  const [finalFileName, setFinalFileName] = React.useState('');

  const [open2, setOpen2] = React.useState({
    status: false,
    type: '',
    message: '',
  });

  const handleChange = (e) => {
    setFiles(e);
  };

  const uploadArquivo = (e) => {
    setLoading(true);

    const data = new FormData();
    // data.append('file', files[0]);
    // data.append('get_line_distance', true);

    // console.log("dados: ", files[0]);

    var files2 = files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      setDados(dataParse);
      // console.log(dataParse);
      // adicional
      setTamTotal(dataParse.length);
      setAjustar(true);
      // ajustarDistancia();
    };
    reader.readAsBinaryString(f);
    // console.log(files[0]);
    setNomeDoArquivo(f.name);

    setLoading(false);
  };

  const consultarRuas = async () => {
    setLoading(true);

    
    for (let i = 1; i <= dados.length - 1; i++) {
      var strDados = '' + dados[i];
      var strCoordenadas = ('' + dados[i][3]).replace(',0', '');
      var coordFormat = {
        lng: strCoordenadas.substring(0, strCoordenadas.indexOf(',')),
        lat: strCoordenadas.substring(
          strCoordenadas.indexOf(',') + 1,
          strCoordenadas.length,
        ),
      };

      await getRoads(coordFormat.lat,coordFormat.lng
        )
        .then(async (resp) => {
          var aux = ruas
          aux.push({
            nome: dados[i][0],
            descricao: dados[i][1],
            tipo: dados[i][2],
            coordenadas: dados[i][3],
            Rua: resp.address.road ?? resp.name,
            Bairro: resp.address.suburb ?? ' - ',
            });
            setRuas(aux);
        })
        .catch((error) => {
          var aux = ruas;
          console.log('Erro: ', error);
          aux.push({
            nome: dados[i][0],
            descricao: dados[i][1],
            tipo: dados[i][2],
            coordenadas: dados[i][3],
            Rua: ' - ',
            Bairro: ' - ',
          });
          setRuas(aux);
        });
    }

    setLoading(false);
    setBaixar(true);
  };

  var convert = function () {
    var columns = [
      { label: 'Nome', value: (row) => row.nome }, // Top level data
      { label: 'Descrição', value: (row) => row.descricao }, // Run functions
      { label: 'Tipo', value: (row) => row.tipo }, // Run functions
      { label: 'Coordenadas', value: (row) => row.coordenadas }, // Run functions
      { label: 'Rua', value: (row) => row.Rua }, // Deep props
      { label: 'Bairro', value: (row) => row.Bairro },
    ];

    // var content = ruas.sort(function (a, b) {
    //   if (a.ID > b.ID) {
    //     return 1;
    //   }
    //   if (a.ID < b.ID) {
    //     return -1;
    //   }
    //   // a must be equal to b
    //   return 0;
    // });;
    var content = ruas;

    var settings = {
      // sheetName: 'sheet', // The name of the sheet
      fileName: `nome_das_ruas_${nomeDoArquivo
        .replace('.xlsx', '')
        .replace('.xls', '')}`, // The name of the spreadsheet
      extraLength: 3, // A bigger number means that columns should be wider
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };
    var download = true; // If true will download the xlsx file, otherwise will return a buffer

    let data = [
      {
        sheet: 'sheet',
        columns: columns,
        content: content,
      },
    ];

    xlsx(data, settings);
    setLoading(false);
  };

  // const consultarDownload=e=>{

  //   setLoading(true);
  //   const config = {
  //       headers: {
  //         "Authorization": "Bearer " + props.user.token,
  //         "responseType": "arraybuffer"
  //       }
  //     };

  //   API.get(`kml-to-xls/${e}`, config)
  //   .then(function(response) {
  //         const url = window.URL.createObjectURL(new Blob([response.data]));
  //         const link = document.createElement('a');
  //         link.href = url;
  //         const fileName = `${+ new Date()}.kml`// whatever your file name .
  //         link.setAttribute('download', fileName);
  //         document.body.appendChild(link);
  //         link.click();
  //         link.remove();// you need to remove that elelment which is created before.
  //     })
  //     .catch(function(error) {
  //           console.log(error.response)
  //   });
  // }

  React.useEffect(
    (e) => {
      if (files) {
        if (files != fileantigo) {
          if (files.length > 0) uploadArquivo();
          setFileantigo(files);
        }
      }
    },
    [fileantigo, files, uploadArquivo],
  );

  return (
    <Container fluid className="m-0 p-0">
      <Conteudo>
        <div className="contido">
          <div className="titulodapage">
            <h3>{'Nome das Ruas'}</h3>
            Nesta aplicação é possível obter o nome das ruas de um
            ponto/marcador de acordo com sua posição. Envie seu arquivo XLSX.
          </div>

          <br />
          <br />
          <DropzoneArea
            dropzoneClass="campodeupicon"
            dropzoneParagraphClass="campodeup"
            onChange={(e) => {
              handleChange(e);
              setShowfile(true);
            }}
            onDelete={(e) => {
              setLinkfile('');
              setLinestring([]);
              setRuas([]);
              setFiles();
              setDados();
              setDados2([]);
              setAjustar(false);
              setBaixar(false);
            }}
            acceptedFiles={['.xlsx', '.xls']}
            filesLimit={1}
            maxFileSize={100000000}
            dropzoneText={`Arraste seu arquivo para este campo ou clique aqui para buscar`}
            useChipsForPreview={true}
            showPreviewsInDropzone={showfile}
            getFileAddedMessage={(fileName) =>
              `Arquivo ${fileName} adicionado com sucesso.`
            }
            getFileRemovedMessage={(fileName) =>
              `Arquivo ${fileName} removido.`
            }
            getFileLimitExceedMessage={(filesLimit) =>
              `Número máximo de ${filesLimit} arquivos excedido.`
            }
            getDropRejectMessage={() => `Tipo de arquivo não permitido.`}
          />
          <br />
          {/* <a href={linkfile? `${linkfile}?t=${props.user.token}`: '#'} style={{pointerEvents: linkfile? '': 'none'}}> */}
          <Button
            variant="contained"
            color="primary"
            disabled={ajustar && !baixar ? false : true}
            onClick={() => consultarRuas()}
          >
            Consultar
          </Button>
          {'  '}
          <Button
            variant="contained"
            color="secondary"
            disabled={baixar ? false : true}
            onClick={() => {
              setLoading(true);
              // console.log(linkfile);
              convert();
            }}
          >
            Download
          </Button>
          {/* </a> */}
          {linestring.length > 0 ? (
            <div style={{ maxWidth: '650px', margin: '1rem auto' }}>
              <Alert
                severity="info"
                onClick={() => setShow(true)}
                style={{ cursor: 'pointer' }}
              >
                Notamos a presença de uma ou mais linhas no seu arquivo,{' '}
                <b>clique aqui</b> para visualizar seus comprimentos.
              </Alert>
            </div>
          ) : (
            ''
          )}

          <a href={archivemodel} target="_blank">
            <div
              style={{
                textAlign: 'left',
                marginTop: '1rem',
                fontSize: '14px',
                color: '#005351',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              Baixar modelo
            </div>
          </a>
        </div>

        <br />
        <br />
        <div className="divisor" />
        {/* <Alert className="balaoNoticia" variant="filled" severity="info">Nesta aplicação já foram convertidos cerca de <b>369</b> arquivos.</Alert> */}

        {show ? (
          <Kmlparaxlsdialog
            tamanho="md"
            message={[linestring, distanciatotal]}
            negar={() => setShow(false)}
          />
        ) : (
          ''
        )}
      </Conteudo>
      <Snackbar
        open={open2.status}
        autoHideDuration={6000}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          setOpen2({ status: false, type: '', message: '' });
        }}
      >
        <Alert
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setOpen2({ status: false, type: '', message: '' });
          }}
          severity={open2.type}
        >
          {open2.message}
        </Alert>
      </Snackbar>
      {loading && <Backdrop/>}
      <Alertalogin />
    </Container>
  );
}

export default Nomedasruas;
